import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button/Button";
import TimeOffConfigurationEmpty from "./TimeOffConfigurationEmpty";
import ToggleButton from "../../../components/ToogleButton/ToogleButton";
import Typography from "../../../components/Typography/Typography";
import AdminCreateTimeOffModal from "./AdminCreateTimeOffModal";
import AdminEditTimeOffModal from "./AdminEditTimeOffModal";
import AdminDeleteTimeOffConfirmModal from "./AdminDeleteTimeOffConfirmModal";
import { useAppDispatch } from "../../../hooks";
import { getAdminTimeoffListAsync } from "../../../services/reducers/leaveReducer";
import CreateTimeOffTypeModal from "./CreateTimeOffTypeModal";

type Props = {
  activeTab: string;
  financialyearList: any;
};

const TimeOffConfiguration: React.FC<Props> = ({
  activeTab,
  financialyearList,
}) => {
  const [createTimeOff, setCreateTimeOff] = useState<boolean>(false);
  const [editTimeOff, setEditTimeOff] = useState<boolean>(false);
  const [deleteTimeOff, setDeleteTimeOff] = useState<boolean>(false);
  const [deleteTimeOffUsed, setDeleteTimeOffUsed] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [timeList, setTimeList] = useState<any>([]);
  const [showTimeType, setShowTimeType] = useState(false);
  const [selectedTimeOffType, setSelectedTimeOffType] = useState<number>(0);
  const [timeOffId, setTimeOffId] = useState("");
  const isFetching = useRef(false);
  const [createTimeOffType, setCreateTimeOffType] = useState<boolean>(false);
  useEffect(() => {
    if (activeTab === "tab4") {
      getTimeoffList();
    }
  }, [selectedTimeOffType, activeTab]);

  const getTimeoffList = () => {
    if (isFetching.current) return;

    isFetching.current = true;
    const request: any = {
      Page: 1,
      PageSize: 50,
    };
    dispatch(getAdminTimeoffListAsync(request)).then((res) => {
      if (res.payload) {
        setTimeList(res.payload.records);
        isFetching.current = false;
      }
    });
  };

  const getRecordsCountByType = (type: number) => {
    let totalCount = 0;
    timeList.forEach((item: any) => {
      if (item.timeOffType === type) {
        totalCount += item.recordsCount;
      }
    });
    return totalCount;
  };

  const handleToggle = () => {
    setShowTimeType(!showTimeType);
    setSelectedTimeOffType(selectedTimeOffType === 0 ? 1 : 0);
  };

  const filteredTimeList = timeList.filter(
    (item: any) => item.timeOffType === selectedTimeOffType
  );

  const formatDate = (inputDate: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const date = new Date(inputDate);
    return date.toLocaleDateString("en-GB", options);
  };

  const handleDelete = (id: string, isConsumed: boolean) => {
    setDeleteTimeOff(true);
    setDeleteTimeOffUsed(isConsumed);
    setTimeOffId(id);
  };

  const handleEdit = (id: string) => {
    setTimeOffId(id);
    setEditTimeOff(true);
  };

  return (
    <div className="px-10 relative">
      <div className="absolute right-12 -top-[3.125rem]">
        <Button
          onClick={() => setCreateTimeOff(true)}
          // onClick={() => setCreateTimeOffType(true)}
          label="+ Time Off Type"
          variant="primary"
          size="extramedium"
        />
      </div>
      <div className="border border-gray-100 shadow-300 bg-white rounded-md relative h-[calc(100vh-16rem)]">
        <div className="px-10 pt-10 flex justify-end items-center gap-3 [&>label>span.ml-2]:m-0">
          <div
            className={`text-300 text-black ${selectedTimeOffType === 0 ? "font-bold" : "font-normal"
              }`}
          >
            Holidays<span className="ms-1">({getRecordsCountByType(0)})</span>
          </div>
          <ToggleButton
            className=""
            label=""
            onChange={() => handleToggle()}
            type="timeOff"
          />
          <div
            className={`text-300 text-black ${selectedTimeOffType === 1 ? "font-bold" : "font-normal"
              }`}
          >
            Leaves<span className="ms-1">({getRecordsCountByType(1)})</span>
          </div>
        </div>
        <div className="px-10 overflow-auto h-[calc(100%-4rem)]">
          {filteredTimeList?.length > 0 ? (
            filteredTimeList.map(
              (item: any) =>
                Array.isArray(item.timeOffData) &&
                item.timeOffData.map((items: any) => (
                  <div className="pb-10 pt-5 border-b border-gray-400">
                    <div className="flex justify-start items-center gap-2">
                      <Typography
                        variant="h4"
                        className="font-medium text-gray-300"
                      >
                        {items.title}
                      </Typography>
                      {items.timeOffType === 0 && items.holidayType === 0 && (
                        <span className="bg-green-100 text-200 px-2.5 py-1 rounded-2xl text-white">Fixed</span>
                      )}
                      {items.timeOffType === 0 && items.holidayType === 1 && (
                        <span className="bg-yellow-400 text-200 px-2.5 py-1 rounded-2xl text-white">Restricted</span>
                      )}
                    </div>
                    <div className="flex justify-between items-center mt-5">
                      <div className="flex justify-start items-center gap-5">
                        {selectedTimeOffType === 1 && (
                          <div className="px-4 py-[0.438rem] text-500 text-gray-300 font-normal border border-gray-100 rounded-md min-w-[6.438rem]">
                            {items.maxLeaves}{" "}
                            {items.maxLeaves !== null && items.maxLeaves > 1
                              ? "Days"
                              : "Day"}
                          </div>
                        )}
                        <div className="text-500 text-gray-300 font-normal">
                          {selectedTimeOffType === 0
                            ? items.endDate
                              ? `${formatDate(items.startDate)} - ${formatDate(
                                items.endDate
                              )}`
                              : formatDate(items.startDate)
                            : `Max Leaves${items.isApplyOnProration === true
                              ? ` / ${items.occurrenceMode === 0
                                ? "Monthly"
                                : items.occurrenceMode === 1
                                  ? "Quarterly"
                                  : items.occurrenceMode === 2
                                    ? "Half Yearly"
                                    : items.occurrenceMode === 3
                                      ? "Annually"
                                    : items.occurrenceMode === 4
                                    ? "One-Time"
                                      : ""
                              }`
                              : ""
                            }`
                            // : `Max Leaves 
                            // / ${
                            //     items.occurrenceMode === 0
                            //       ? "Monthly"
                            //       : items.occurrenceMode === 1
                            //       ? "Quarterly"
                            //       : items.occurrenceMode === 2
                            //       ? "Half Yearly"
                            //       : items.occurrenceMode === 3
                            //       ? "Annually"
                            //       : ""
                            //   }`
                          }
                        </div>
                      </div>

                      <div className="flex justify-end items-center gap-6">
                        <button onClick={() => handleEdit(items?.id)}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.2599 3.59997L5.04985 12.29C4.73985 12.62 4.43985 13.27 4.37985 13.72L4.00985 16.96C3.87985 18.13 4.71985 18.93 5.87985 18.73L9.09985 18.18C9.54985 18.1 10.1799 17.77 10.4899 17.43L18.6999 8.73997C20.1199 7.23997 20.7599 5.52997 18.5499 3.43997C16.3499 1.36997 14.6799 2.09997 13.2599 3.59997Z"
                              stroke="#232323"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M11.8901 5.05005C12.3201 7.81005 14.5601 9.92005 17.3401 10.2"
                              stroke="#232323"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3 22H21"
                              stroke="#232323"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                        {(items?.leaveCode !== "LOP" && items?.leaveCode !== "COFF") && (
                          <button
                            onClick={() =>
                              handleDelete(items?.id, items?.isConsumed)
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                                stroke="#232323"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                                stroke="#232323"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M18.8494 9.14014L18.1994 19.2101C18.0894 20.7801 17.9994 22.0001 15.2094 22.0001H8.78941C5.99941 22.0001 5.90941 20.7801 5.79941 19.2101L5.14941 9.14014"
                                stroke="#232323"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10.3301 16.5H13.6601"
                                stroke="#232323"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.5 12.5H14.5"
                                stroke="#232323"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        )}
                      </div>

                    </div>
                  </div>
                ))
            )
          ) : (
            <TimeOffConfigurationEmpty
              selectedTimeOffType={selectedTimeOffType}
            />
          )}
        </div>
        {/* Leaves End */}
      </div>
      {createTimeOff && (
        <AdminCreateTimeOffModal
          onClose={() => setCreateTimeOff(false)}
          getTimeoffList={getTimeoffList}
          setCreateTimeOff={setCreateTimeOff}
          financialyearList={financialyearList}
          selectedTimeOffType={selectedTimeOffType}
        />
      )}
      {editTimeOff && (
        <AdminEditTimeOffModal
          onClose={() => setEditTimeOff(false)}
          timeOffId={timeOffId}
          getTimeoffList={getTimeoffList}
          setEditTimeOff={setEditTimeOff}
        />
      )}
      {deleteTimeOff && (
        <AdminDeleteTimeOffConfirmModal
          onClose={() => setDeleteTimeOff(false)}
          timeOffId={timeOffId}
          getTimeoffList={getTimeoffList}
          setDeleteTimeOff={setDeleteTimeOff}
          deleteTimeOffUsed={deleteTimeOffUsed}
        />
      )}
      {/* {createTimeOffType && (
        <CreateTimeOffTypeModal
          onClose={() => setCreateTimeOffType(false)}
        />
      )} */}
    </div>
  );
};

export default TimeOffConfiguration;
