import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../sharedService/constants";
import { commonDataAll, createIntegration, deleteIntegration, getAllUsers, getEmployeeEnumList, getIntegrationKeyList, getIntegrationList, getNotificationList, getReportees, getRole, getTeams, getUserDetails, getUserIdentity, getUserProfile, updateNotification } from "../api/appApis";

const initialState = {
  isLoading: false,
  isError: false,
  createIntegaration: {},
  userProfile: [],
  currentUser: null,
  getIntegrationList: {},
  users: { records: [] },
  teamsList: [],
  reportees: { users: [] },
  getNotification: [],
  roleList: [],
  getEmployeeList:[],
  getGenderList:[]
};

export const createIntegrationAsync = createAsyncThunk(
  "appintegration/createIntegration",
  async (request: any) => {
    const response = await createIntegration(request);
    return response.data;
  }
);

export const getIntegrationListAsync = createAsyncThunk(
  "appintegration/getIntegrationList",
  async () => {
    const response = await getIntegrationList();
    return response.data;
  }
);
export const getUserProfileAsync = createAsyncThunk(
  "getUserProfile",
  async () => {
    const response = await getUserProfile();
    return response.data;
  }
);

export const getUserDetailsAsync = createAsyncThunk(
  "getUserDetails",
  async (id: string) => {
    const response = await getUserDetails(id);
    return response.data;
  }
);

export const getUserIdentityAsync = createAsyncThunk(
  "getUserIdentity",
  async (id: string) => {
    const response = await getUserIdentity(id);
    return response.data;
  }
);

export const deleteIntegrationAsync = createAsyncThunk(
  "timesheet/delete",
  async (identifire: any) => {
    const response = await deleteIntegration(identifire);
    return response.data;
  }
);

export const getUsersAsync = createAsyncThunk(
  "getAllUsers",
  async (request: any) => {
    const response = await getAllUsers(request);

    return response.data;
  }
);

export const getTeamsAsync = createAsyncThunk("getTeams", async (request: any) => {
  const response = await getTeams(request);

  return response.data;
});

export const getRoleAsync = createAsyncThunk("getRole", async (request: any) => {
  const response = await getRole(request);

  return response.data;
});

export const getIntegrationKeyListAsync = createAsyncThunk(
  "getUserDetails",
  async (request: any) => {
    const response = await getIntegrationKeyList(request);
    return response.data;
  }
);

export const getReporteesAsync = createAsyncThunk(
  "getReportees",
  async (request: any) => {
    const response = await getReportees(request);
    return response.data;
  }
);

export const getNotificationListAsync = createAsyncThunk(
  "getNotificationList",
  async (request: any) => {
    const response = await getNotificationList(request);
    return response.data;
  }
);

export const updateNotificationAsync = createAsyncThunk(
  'updateNotification',
  async ({ id, reqBody }: { id: string, reqBody: any }) => {
    const response = await updateNotification(id, reqBody);
    return response.data;
  }
);

export const commonDataAllAsync = createAsyncThunk(
  'commonDataAll',
  async ({ typeName, reqBody }: { typeName: string, reqBody: any }) => {
    const response = await commonDataAll(typeName, reqBody);
    return response.data;
  }
);

export const getEmploymentTypeAsync = createAsyncThunk(
  "getEmploymentType",
  async () => {
    const response = await getEmployeeEnumList("EmploymentType");
    return response.data;
  }
);

export const getGenderAsync = createAsyncThunk(
  "getGender",
  async () => {
    const response = await getEmployeeEnumList("Gender");
    return response.data;
  }
);

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createIntegrationAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createIntegrationAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createIntegaration = action.payload
        toast.success('Integration Added Successfully!', TOASTIFY_CONF);
      })
      .addCase(createIntegrationAsync.rejected, (state, action) => {
        // toast.error(`Add Integration, Something went wrong: ${action.payload}`, TOASTIFY_CONF);
        toast.error(`Can not proceed. Platform is already integrated with your account.`, TOASTIFY_CONF);
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getIntegrationListAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getIntegrationListAsync.fulfilled, (state, action) => {
        // console.log("full filled:",action.payload )
        state.isLoading = false;
        state.getIntegrationList = action.payload

      })
      .addCase(getIntegrationListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getUserProfileAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserProfileAsync.fulfilled, (state, action) => {
        // console.log("full filled:",action.payload )
        state.isLoading = false;
        state.userProfile = action.payload

      })
      .addCase(getUserProfileAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getUserDetailsAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserDetailsAsync.fulfilled, (state, action) => {
        // console.log("full filled:",action.payload )
        state.isLoading = false;
        state.currentUser = action.payload

      })
      .addCase(getUserDetailsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getUserIdentityAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUserIdentityAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentUser = action.payload

      })
      .addCase(getUserIdentityAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        // console.log("full filled:",action.payload )
        state.isLoading = false;
        state.users = action.payload

      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getTeamsAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getTeamsAsync.fulfilled, (state, action) => {
        // console.log("full filled:",action.payload )
        state.isLoading = false;
        state.teamsList = action.payload

      })
      .addCase(getTeamsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getRoleAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getRoleAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.roleList = action.payload

      })
      .addCase(getRoleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getReporteesAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getReporteesAsync.fulfilled, (state, action) => {
        // console.log("full filled:",action.payload )
        state.isLoading = false;
        state.reportees = action.payload

      })
      .addCase(getReporteesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

    builder
      .addCase(getNotificationListAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getNotificationListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getNotification = action.payload

      })
      .addCase(getNotificationListAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

      builder
      .addCase(getEmploymentTypeAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getEmploymentTypeAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getEmployeeList = action.payload

      })
      .addCase(getEmploymentTypeAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });

      builder
      .addCase(getGenderAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getGenderAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getGenderList = action.payload

      })
      .addCase(getGenderAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;

      });
  },
});

export default integrationSlice.reducer;
