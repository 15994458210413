import React, { useEffect, useState, ChangeEvent } from "react";
import { Modal } from "../../../components/Modal/Modal";
import { ModalHeader } from "../../../components/Modal/Modal";
import { ModalBody } from "../../../components/Modal/Modal";
import { ModalFooter } from "../../../components/Modal/Modal";
import Typography from "../../../components/Typography/Typography";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import RadioButton from "../../../components/Radio button/RadioButton";
import { SearchMember } from "./SearchMember";
import Icons from "../../../components/Icons/Icon";
import { Dropdown } from "../../../components/DropDown/DropDown";
import Textarea from "../../../components/TextArea/Textarea";
import { Formik, Field, Form, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  checkUniquenessAsync,
  createProjectAsync,
  createTaskAsync,
  getProjectTeamMemberListAsync,
  updateTaskAsync,
} from "../../../services/reducers/adminReducer";
import { toast } from "react-toastify";
import { TOASTIFY_CONF } from "../../../services/sharedService/constants";
import {
  convertHourMinuteToMinutes,
  convertMinutesToHoursAndMinutes2,
} from "../../../utils/common";
import { EchoLoader } from "../../loader/EchopulseLoader";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import styled from "styled-components";

export const CustomRightToolTip = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
    z-index: 9;
    cursor: default;
    width: 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 15rem;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    color: #232323;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    z-index: 1;
    top: -0.313rem;
    left: 104%;
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type Values = {
  taskSummary: string;
  description: string;
  timeEstimate: string;
  dueDate: string;
  privacy: number;
  platform: number;
  project: {
    id: string;
    externalId: string;
    key: string;
    name: string;
    description: string;
    projectType: number;
    platform: number;
  };
  teamMembers: { memberId: string }[];
  identifier: string;
  taskKey: string;
  taskStatus: string;
};

const TaskSchema = Yup.object().shape({
  taskSummary: Yup.string().required("Task name is Required"),
  description: Yup.string().required("Description is Required"),
});
type Props = {
  onClose: () => void;
  userList: any;
  setEditTask: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  taskData: any;
  type: string;
  getTask?: () => void;
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  listTypeValue?: string;
  setSelectedMember?: any;
  selectedMember?: any;
  fetchUserListCustomRequest?: boolean;
  projectId?: string;
  userProfile: any;
};

const EditProjectTaskModal: React.FC<Props> = ({
  onClose,
  userList,
  setEditTask,
  id,
  taskData,
  type,
  getTask,
  searchString,
  setSearchString,
  listTypeValue,
  setSelectedMember,
  selectedMember,
  fetchUserListCustomRequest,
  projectId,
  userProfile,
}) => {
  const dispatch = useAppDispatch();
  const [isUniqueTitle, setIsUniqueTitle] = useState(false);
  const [titleFocused, setTitleFocused] = useState(false);
  const [userLists, setUserLists] = useState<any>(null);
  const [isCheckFirstTitleDuplicate, setIsCheckFirstTitleDuplicate] =
    useState(true);
  const { isLoading } = useAppSelector((state: any) => state.admin);
  const [currentPage, setCurrentPage] = useState(1);
  const users = useAppSelector((state: any) => state.app.users.users);
  const [showAll, setShowAll] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const search = () => {
    const request = {
      ProjectId: projectId,
      Page: currentPage,
      PageSize: 2000,
      Team: "",
      IsOrderByDesc: false,
    };
    dispatch(getProjectTeamMemberListAsync(request)).then((res) => {
      setUserLists(res?.payload?.item2);
    });
  };

  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    debouncedSearch();
  }, [searchString]);

  const validateTitle = async (title: string) => {
    setIsCheckFirstTitleDuplicate(false);
    if (title.trim() !== "") {
      const jsonData = {
        propertyName: "title",
        checkValue: title,
        projectId: projectId,
      };
      if (type !== "edit") {
        await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
          setIsUniqueTitle(res?.payload ? true : false);
          setIsCheckFirstTitleDuplicate(false);
          setTitleFocused(false);
        });
      } else {
        const currentTitle = taskData?.taskSummary;
        if (title !== currentTitle) {
          await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
            setIsUniqueTitle(res?.payload ? true : false);
            setIsCheckFirstTitleDuplicate(false);
            setTitleFocused(false);
          });
        } else {
          setIsUniqueTitle(false);
          setIsCheckFirstTitleDuplicate(true);
          setTitleFocused(false);
        }
      }
    }
  };

  const removeMember = (memberIdToRemove: string) => {
    setSelectedMember((prevMembers: any) =>
      prevMembers.filter(
        (member: any) => member.identityId !== memberIdToRemove
      )
    );
  };

  const debouncedValidateTitle = useDebounce(validateTitle, 500);

  const toggleShowAllMembers = () => {
    setShowAll(!showAll);
  };

  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <Formik
        enableReinitialize
        initialValues={{
          taskSummary: id ? taskData?.taskSummary : "",
          description: id ? taskData?.description : "",
          dueDate: id ? taskData?.dueDate : "",
          privacy: id ? taskData?.privacy : 1,
          timeEstimate: id
            ? convertMinutesToHoursAndMinutes2(taskData?.timeEstimate)
            : "",
          platform: 2,
          project: {
            id: id ? taskData?.project?.id : "",
            externalId: "",
            key: id ? taskData?.project?.key : "",
            name: id ? taskData?.project?.name : "",
            description: id ? taskData?.project?.description : "",
            projectType: id ? taskData?.project?.projectType : 0,
            platform: 2,
          },
          teamMembers: [
            {
              memberId:
                id && taskData?.teamMembersList
                  ? taskData.teamMembersList.map((t: any) => t?.name)
                  : [],
            },
          ],
          identifier: id ? taskData?.identifier : "",
          taskKey: id ? taskData?.taskKey : "",
          taskStatus: id ? taskData?.taskStatus : "",
        }}
        validationSchema={TaskSchema}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          setIsSubmitting(true);
          let request: any = values;
          const timeEstimateValue = convertHourMinuteToMinutes(
            values.timeEstimate
          );
          const members = values.privacy === 0 ? selectedMember : userLists;
          request = {
            ...request,
            privacy: values.privacy,
            timeEstimate: timeEstimateValue,
            platform: 2,
            project: {
              id: values?.project?.id,
              externalId: "",
              key: values?.project?.key,
              name: values?.project?.name,
              description: values?.project?.description,
              projectType: values?.project?.projectType,
              dueDate: id ? values?.dueDate : "",
              platform: 2,
            },
            teamMembers: members.map((member: any) => ({
              memberId: member.identityId,
            })),
            taskStatus:
              type === "edit"
                ? listTypeValue === "all"
                  ? 0
                  : listTypeValue === "closed"
                  ? 1
                  : null
                : 0,
          };
          let result;
          if (type === "edit") {
            result = await dispatch(updateTaskAsync({ id: id, request }));
          } else {
            result = await dispatch(createTaskAsync(request));
          }
          setEditTask(false);
          getTask && getTask();

          if (result) {
            toast.success(`Task Updated Successfully!`, TOASTIFY_CONF);
          } else {
            toast.success(
              `Duplicate Task Created successfully!`,
              TOASTIFY_CONF
            );
          }
          setSubmitting(false);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          isSubmitting,
          setFieldTouched,
          submitForm,
          validateField,
          setTouched,
          isValid,
          dirty,
        }) => {
          return (
            <Form className="w-full flex flex-col h-full">
              <ModalHeader className="bg-gray-200">
                <Typography className="font-bold" variant="h3">
                  {type === "edit" ? "Edit Task" : type === "clone" ? "Copy Task" : "Add Task"}
                </Typography>
              </ModalHeader>
              <ModalBody className="px-20 py-10">
                <div className="grid grid-cols-2 gap-x-5 ">
                  <div className="w-[25.313rem]">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Task Name <span className="text-primary-100">*</span>
                    </label>
                    <Field
                      name="taskSummary"
                      render={({ field }: any) => (
                        <>
                          <TextInput
                            {...field}
                            onChange={(event: any) => {
                              setFieldValue("taskSummary", event.target.value);
                              debouncedValidateTitle(event.target.value);
                            }}
                            onBlur={() => setFieldTouched("taskSummary", true)}
                            placeholder="Enter task name"
                            value={field.value}
                            type="text"
                          />
                          {/* {((type !== "edit" &&
                            isCheckFirstTitleDuplicate === true) ||
                            (values.taskSummary && isUniqueTitle === true)) && (
                            <div className="text-primary-100 text-200 font-normal mt-1">
                              {"Task name already exists!"}
                            </div>
                          )} */}
                          {errors.taskSummary && (
                            <div className="text-primary-100 text-200 font-normal mt-1">
                              {errors.taskSummary}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                  {type === "edit" && (
                    <div>
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Task Key <span className="text-primary-100">*</span>
                      </label>
                      <Field
                        name="taskKey"
                        render={({ field }: any) => (
                          <>
                            <TextInput
                              {...field}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              placeholder="Enter task name"
                              value={values?.taskKey}
                              type="text"
                              disabled
                            />
                            {errors.taskKey && touched.taskKey && (
                              <div className="text-primary-100 text-200 font-normal mt-1">
                                {errors.taskKey}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </div>
                  )}
                </div>
                <div className="grid grid-cols-2 mt-8 gap-x-5">
                  <div className="w-[25.313rem] [&>div>textarea]:h-[5.313rem]">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Task Description{" "}
                      <span className="text-primary-100">*</span>
                    </label>
                    <Field
                      name="description"
                      render={({ field }: any) => (
                        <>
                          <Textarea
                            {...field}
                            onChange={(value: string) => {
                              setFieldValue("description", value);
                            }}
                            placeholder="Describe the Task, Objectives, and Expected Outcomes"
                            value={values.description}
                            maxCharacters={250}
                            counter={true}
                            resize
                          />

                          {errors.description && (
                            <div className="text-primary-100 text-200 font-normal mt-1">
                              {errors.description}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="grid col-span-2 mt-6">
                  <div className="">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Task Visibility
                    </label>
                    <div className="flex justify-start items-center gap-10">
                      <Field
                        type="radio"
                        name="privacy"
                        value={1}
                        key="privacy1"
                        render={({ field }: any) => (
                          <RadioButton
                            label="
                            Project-Wide Access"
                            {...field}
                            selectedValue={values.privacy}
                            onChange={() => setFieldValue("privacy", 1)}
                          />
                        )}
                      />
                      <Field
                        type="radio"
                        name="privacy"
                        value={0}
                        key="privacy0"
                        render={({ field }: any) => (
                          <RadioButton
                            label="Restricted Access"
                            {...field}
                            selectedValue={values.privacy}
                            onChange={() => setFieldValue("privacy", 0)}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 mt-8 gap-x-5">
                  <div className="w-[25.313rem]">
                    <label className="text-400 font-medium text-gray-300 block mb-2">
                      Add Time Estimate
                    </label>

                    {userProfile?.roleName === "Super Admin" ||
                    userProfile?.roleName === "Admin" ? (
                      <Field
                        name="timeEstimate"
                        render={({ field }: any) => (
                          <>
                            <TextInput
                              {...field}
                              onBlur={field.onBlur}
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                const inputText = event.target.value;
                                setFieldValue("timeEstimate", inputText);
                              }}
                              placeholder="Enter Estimate (e.g. 2h 30m)"
                              type="text"
                            />
                            {errors.timeEstimate && touched.timeEstimate && (
                              <div className="text-primary-100 text-200 font-normal mt-1">
                                {errors.timeEstimate}
                              </div>
                            )}
                          </>
                        )}
                      />
                    ) : (
                      <CustomRightToolTip>
                        <div className="tooltip">
                          <div className="w-full">
                            <Field
                              name="timeEstimate"
                              render={({ field }: any) => (
                                <>
                                  <TextInput
                                    {...field}
                                    onBlur={field.onBlur}
                                    onChange={(
                                      event: ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const inputText = event.target.value;
                                      setFieldValue("timeEstimate", inputText);
                                    }}
                                    placeholder="Enter Estimate (e.g. 2h 30m)"
                                    type="text"
                                    className="!bg-gray-200 focus:!shadow-0 focus:!border-gray-100"
                                    readOnly
                                  />
                                  {errors.timeEstimate &&
                                    touched.timeEstimate && (
                                      <div className="text-primary-100 text-200 font-normal mt-1">
                                        {errors.timeEstimate}
                                      </div>
                                    )}
                                </>
                              )}
                            />
                          </div>
                          <span className="tooltiptext">
                            Time estimates can only be set by administrators.
                          </span>
                        </div>
                      </CustomRightToolTip>
                    )}
                  </div>

                  {values.privacy === 0 && (
                    <div className="">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Members
                      </label>
                      <SearchMember
                        userList={userList}
                        setSelectedMember={setSelectedMember}
                        selectedMember={selectedMember}
                        searchString={searchString}
                        setSearchString={setSearchString}
                        initialSelectedMembers={
                          taskData.privacy === 1
                            ? []
                            : taskData?.teamMembersList?.map((t: any) => ({
                                name: t?.name,
                                identityId: t?.identityId,
                              }))
                        }
                        fetchUserListCustomRequest={fetchUserListCustomRequest}
                      />

                      <div className="inline-block">
                        {selectedMember.map((t: any, index: number) => (
                          <div
                            key={t.id}
                            className={`inline-block ${
                              index >= 4 && !showAll ? "hidden" : ""
                            }`}
                          >
                            <div className="flex items-center px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
                              <span className="me-1.5">
                                {t?.name}
                                {/* {userLists &&
                                  userLists.find(
                                    (user: any) =>
                                      user.identityId === t?.identityId
                                  )?.name} */}
                              </span>
                              <a onClick={() => removeMember(t.identityId)}>
                                <Icons
                                  name="cross"
                                  variant="stroke"
                                  size={10}
                                />
                              </a>
                            </div>
                          </div>
                        ))}
                        {selectedMember.length > 4 && (
                          <div
                            className="inline-block text-center cursor-pointer px-5 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5"
                            onClick={toggleShowAllMembers}
                          >
                            {showAll
                              ? "Collapse view"
                              : `+${selectedMember.length - 4} others`}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="flex justify-between items-center w-full">
                  <div className="flex justify-start gap-5">
                    <Button
                      label={type === "edit" ? "Update Task" : "Add Task"}
                      variant="primary"
                      size="large"
                      isDisabled={
                        isSubmitting ||
                        !isValid ||
                        // (type !== "edit" &&
                        //   isCheckFirstTitleDuplicate === true) ||
                        (type === "edit" && isUniqueTitle === true)
                      }
                    />
                    <Button
                      label="Discard Changes"
                      onClick={() => setEditTask(false)}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </div>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
      {isLoading && <EchoLoader />}
    </Modal>
  );
};

export default EditProjectTaskModal;
