import React, { useEffect, useState } from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icons from "../../../components/Icons/Icon";
import Pagination from "../projects/Pagination";
import {
  convertMinutesToHoursAndMinutes2,
  convertToAMPM,
  formatsDate,
} from "../../../utils/common";
import ClockInOutEmpty from "./ClockInOutEmpty";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import EchopulseLoader from "../../loader/EchopulseLoader";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getSettingListAsync } from "../../../services/reducers/adminReducer";

export const CustomRightToolTip = styled.div`
  .tooltip {
    position: relative;
    display: inline-block;
    z-index: 9;
    cursor: default;
    width: 100%;
  }

  .tooltiptext {
    visibility: hidden;
    width: 13.438rem;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: 500;
    color: #232323;
    text-align: center;
    border-radius: 0.375rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    z-index: 1;
    top: -0.625rem;
    left: 160%;
    filter: drop-shadow(0 0.5rem 1.5rem rgba(0, 0, 0, 0.12));
  }
  .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -0.625rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: transparent #fff transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

type Props = {
  clockLists: any;
  loading: boolean;
  clockTimeLog: any;
  currentPage: number;
  pageSize: number;
  count: any;
  filterss: boolean;
  setFilterss: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  clockLog: any;
  timeOffLog: any;
};
const ClockInOut: React.FC<Props> = ({
  clockLists,
  loading,
  clockTimeLog,
  currentPage,
  pageSize,
  count,
  filterss,
  setFilterss,
  setCurrentPage,
  clockLog,
  timeOffLog,
}) => {
  const processedDates = new Set();
  const navigate = useNavigate();
  let totalTimeSpent = 0;
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );
  const dispatch = useAppDispatch();
  const getSettingList = useAppSelector(
    (state: any) => state.admin.getSettingList?.records
  );

  useEffect(() => {
    dispatch(getSettingListAsync());
  }, []);

  const toggleSectionAccordion = (index: number) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const isWeekend = (dateString: any) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  clockLists.forEach((task: any) => {
    task.timeLogs
      .slice((currentPage - 1) * pageSize, currentPage * pageSize)
      .forEach((log: any) => {
        totalTimeSpent += log.timeSpent;
      });
  });

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleView = (id: string) => {
    navigate(`/teams/${id}`);
  };

  const filteredTimeOffLogs = timeOffLog.filter(
    (log: any) => log.timeOffType === "Leave" || log.timeOffType === "Holiday"
  );

  const countTotal = clockLists.reduce((total: number, t: any) => {
    if (t.totalTimeSpent) {
      total += t.totalTimeSpent;
    }
    return total;
  }, 0);

  return (
    <div className="border border-gray-100 shadow-300 bg-white rounded-md relative pb-0 h-[calc(100vh-11.1rem)]">
      <div className="overflow-auto h-[calc(100vh-14.5rem)]">
        {loading ? (
          <EchopulseLoader />
        ) : (
          <>
            <div className="h-[3.75rem] bg-gray-200 rounded-tr-md sticky left-0 top-0 z-50 ps-6 pe-6 rounded-t-md">
              <div className="flex justify-start gap-2 h-full">
                <div className="flex items-center justify-start w-[calc(100%-44rem)]">
                  <div className="text-400 font-bold text-gray-300">
                    Employee Name
                  </div>
                </div>
                <div className="flex justify-end text-center gap-[0.75rem]">
                  {clockTimeLog.map((task: any) => {
                    const currentDate = task.date;
                    const dayKey = `${currentDate}_${new Date(
                      currentDate
                    ).toLocaleDateString("en-US", { weekday: "short" })}`;
                    if (processedDates.has(dayKey)) {
                      return null;
                    }
                    processedDates.add(dayKey);
                    const isWeekendWithTimeSpent =
                      isWeekend(currentDate) &&
                      clockTimeLog.some(
                        (t: any) => t.date === currentDate && t.timeSpent > 0
                      );

                    return (
                      <div
                        key={currentDate}
                        className={`flex justify-center items-center w-[5rem] h-full relative ${isWeekend(currentDate)
                          ? isWeekendWithTimeSpent
                            ? "opacity-100"
                            : "opacity-50"
                          : ""
                          }`}
                      >
                        <div>
                          <div className={`text-400 font-bold text-gray-300`}>
                            {new Date(currentDate).toLocaleDateString("en-US", {
                              weekday: "short",
                            })}
                          </div>
                          <div className={`text-200 font-normal text-gray-300`}>
                            {new Date(currentDate).toLocaleDateString("en-US", {
                              month: "short",
                            })}{" "}
                            {new Date(currentDate).toLocaleDateString("en-US", {
                              day: "2-digit",
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="flex justify-end items-center w-[5rem] h-full">
                    <div>
                      <div className="text-400 font-bold text-gray-300">
                        Total Hours
                      </div>
                      <div className="text-200 font-bold text-primary-100">
                        {/* {convertMinutesToHoursAndMinutes2(totalTimeSpent)} */}
                        {convertMinutesToHoursAndMinutes2(countTotal)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {clockLists?.length > 0 ? (
              clockLists.map((t: any, index: number) =>
                t.employeeProfile !== null ? (
                  <div className={`px-6  ${openSections[index] ? "" : ""}`}>
                    <div className="border-b border-gray-400">
                      <div className="flex justify-start items-center gap-2 w-full h-full py-4">
                        <div
                          className="cursor-pointer mb-4"
                          onClick={() => toggleSectionAccordion(index)}
                        >
                          <svg
                            style={{
                              transform: openSections[index]
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                              transition: "transform 0.3s ease",
                            }}
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.28 5.96668L8.9333 10.3133C8.41997 10.8267 7.57997 10.8267 7.06664 10.3133L2.71997 5.96667"
                              stroke="#292D32"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="relative w-full">
                          <div className="flex justify-start gap-2">
                            <div className="flex items-center justify-start w-[calc(100%-44rem)] h-full gap-2 pe-5">
                              <div className="flex flex-1 justify-start items-center">
                                <div className="w-8 h-8 rounded-full overflow-hidden">
                                  {t?.employeeProfile?.profilePic ? (
                                    <UserImage
                                      imgKey={t?.employeeProfile?.profilePic}
                                      className="w-full h-full object-cover"
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        t?.employeeProfile?.name ?? "N A",
                                        24
                                      )}
                                      alt={t?.name}
                                      className="w-full h-full object-cover"
                                    />
                                  )}
                                </div>
                                <div className="ms-2.5 w-[calc(100%-2.625rem)]">
                                  <div
                                    className={`flex items-center text-400 font-medium cursor-pointer ${t.timeLogs.some((log: any) => {
                                      const dayOfWeek = new Date(
                                        log.date
                                      ).getDay();
                                      const currentDate = new Date(log.date);
                                      const today = new Date();
                                      const isToday =
                                        currentDate.toLocaleDateString() ===
                                        today.toLocaleDateString();
                                      const isPastDay = currentDate < today;
                                      return (
                                        dayOfWeek >= 1 &&
                                        dayOfWeek <= 5 &&
                                        isPastDay &&
                                        !isToday &&
                                        !log.logedMinimum
                                      );
                                    })
                                      ? "text-error"
                                      : "text-gray-300"
                                      }`}
                                    onClick={() =>
                                      handleView(t?.employeeProfile?.identityId)
                                    }
                                  >
                                    <span
                                      className={`line-clamp-1 ${t.timeLogs.some((log: any) => {
                                        const dayOfWeek = new Date(
                                          log.date
                                        ).getDay();
                                        const currentDate = new Date(
                                          log.date
                                        );
                                        const today = new Date();
                                        const isToday =
                                          currentDate.toLocaleDateString() ===
                                          today.toLocaleDateString();
                                        const isPastDay = currentDate < today;
                                        return (
                                          dayOfWeek >= 1 &&
                                          dayOfWeek <= 5 &&
                                          isPastDay &&
                                          !isToday &&
                                          !log.logedMinimum
                                        );
                                      })
                                        ? "max-w-[95%]"
                                        : ""
                                        }`}
                                    >
                                      {t?.employeeProfile?.name}
                                    </span>
                                    {t.timeLogs.some((log: any) => {
                                      const dayOfWeek = new Date(
                                        log.date
                                      ).getDay();
                                      const currentDate = new Date(log.date);
                                      const today = new Date();
                                      const isToday =
                                        currentDate.toLocaleDateString() ===
                                        today.toLocaleDateString();
                                      const isPastDay = currentDate < today;
                                      return (
                                        dayOfWeek >= 1 &&
                                        dayOfWeek <= 5 &&
                                        isPastDay &&
                                        !isToday &&
                                        !log.logedMinimum
                                      );
                                    }) && (
                                        <div className="w-auto">
                                          <Tooltip
                                            content={`Previous Day's Timesheet Below ${getSettingList[0]?.minimumHour}-Hour Requirement`}
                                            placement="right"
                                            trigger
                                          >
                                            <Icons
                                              name="info-circle-2"
                                              className="ms-1.5 mt-2"
                                              variant="bold"
                                              size={14}
                                            />
                                          </Tooltip>
                                        </div>
                                      )}

                                  </div>
                                  <div className="text-200 text-gray-300 font-normal line-clamp-1 w-full">
                                    {t?.employeeProfile?.designation} |{" "}
                                    {t?.employeeProfile?.team}
                                  </div>
                                </div>
                              </div>
                              <div className="text-center">
                                <span
                                  className={`${t.actionOnTimeSheet === null
                                    ? "bg-secondary-300"
                                    : t.actionOnTimeSheet === 0
                                      ? "bg-blue-300"
                                      : t.actionOnTimeSheet === 1
                                        ? "bg-error"
                                        : t.actionOnTimeSheet === 2
                                          ? "bg-green-100"
                                          : ""
                                    } text-200 px-2.5 py-1 rounded-2xl text-white`}
                                >
                                  {t.actionOnTimeSheet === null
                                    ? "Not submitted"
                                    : t.actionOnTimeSheet === 0
                                      ? "Submitted"
                                      : t.actionOnTimeSheet === 1
                                        ? "Pushed Back"
                                        : t.actionOnTimeSheet === 2
                                          ? "Approved"
                                          : ""}
                                </span>
                                {t.actionOnTimeTaken && (
                                  <span className="text-100 font-light text-gray-300 block mt-0.5">{formatsDate(t.actionOnTimeTaken)}</span>)}
                              </div>
                            </div>
                            <div className="flex justify-end text-center gap-[0.75rem]">
                              {filteredTimeOffLogs &&
                                t.timeLogs.map((t1: any, index: number) => {
                                  const matchedTimeOffLogs =
                                    filteredTimeOffLogs.filter(
                                      (log: any) =>
                                        log.date === t1.date &&
                                        log.identityId ===
                                        t?.employeeProfile?.identityId
                                    );

                                  if (matchedTimeOffLogs.length > 0) {
                                    const totalTimeSpent =
                                      matchedTimeOffLogs.reduce(
                                        (total: number, log: any) =>
                                          total + log.timeSpent,
                                        0
                                      );

                                    const timeOffType = matchedTimeOffLogs.find(
                                      (log: any) =>
                                        log.timeOffType === "Holiday"
                                    )
                                      ? "Holiday"
                                      : "Leave";

                                    return (
                                      <div
                                        key={t1.date}
                                        className="flex justify-center items-center w-[5rem] h-[2.813rem] bg-gray-1000 rounded-md"
                                      >
                                        <div className="text-400 font-bold text-secondary-100"
                                        // className={`text-400 ${
                                        //   (matchedTimeOffLog.timeOffType ===
                                        //     "Holiday" &&
                                        //     "font-bold text-primary-100") ||
                                        //   (matchedTimeOffLog.timeOffType ===
                                        //     "Leave" &&
                                        //     "font-bold text-secondary-100")
                                        // }`}
                                        >
                                          {timeOffType === "Holiday" &&
                                            (totalTimeSpent || t1.timeSpent
                                              ? convertMinutesToHoursAndMinutes2(
                                                totalTimeSpent + t1.timeSpent
                                              )
                                              : "0h")}
                                          {timeOffType === "Leave" &&
                                            (totalTimeSpent || t1.timeSpent
                                              ? convertMinutesToHoursAndMinutes2(
                                                totalTimeSpent + t1.timeSpent
                                              )
                                              : "0h")}
                                          <br />
                                          {/* {timeOffType === "Holiday" && (
                                            <span className="text-100 text-primary-100 font-bold">
                                              HOLIDAY
                                            </span>
                                          )}
                                          {timeOffType === "Leave" && (
                                            <span className="text-100 text-secondary-100 font-bold">
                                              ON LEAVE
                                            </span>
                                          )} */}
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        key={t1.date}
                                        className="flex justify-center items-center w-[5rem] h-[2.813rem] bg-gray-1000 rounded-md"
                                      >
                                        <div className="text-400 font-normal text-gray-300">
                                          {t1.timeSpent > 0
                                            ? convertMinutesToHoursAndMinutes2(
                                              t1.timeSpent
                                            )
                                            : "0h"}
                                        </div>
                                      </div>
                                    );
                                  }
                                })}

                              <div className="flex justify-center items-center w-[5rem] h-full">
                                <div
                                  className={`text-400 font-bold ${t.timeLogs.some((log: any) => {
                                    const dayOfWeek = new Date(
                                      log.date
                                    ).getDay();
                                    const currentDate = new Date(log.date);
                                    const today = new Date();
                                    const isToday =
                                      currentDate.toLocaleDateString() ===
                                      today.toLocaleDateString();
                                    const isPastDay = currentDate < today;
                                    return (
                                      dayOfWeek >= 1 &&
                                      dayOfWeek <= 5 &&
                                      isPastDay &&
                                      !isToday &&
                                      !log.logedMinimum
                                    );
                                  })
                                    ? "text-primary-100"
                                    : "text-green-100"
                                    }`}
                                >
                                  {t.totalTimeSpent > 0
                                    ? convertMinutesToHoursAndMinutes2(
                                      t.totalTimeSpent
                                    )
                                    : "0h"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {openSections[index] && (
                        <div className="px-16">
                          <div className="flex justify-start gap-2 h-[3.75rem] border-y border-gray-400">
                            <div className="flex items-center justify-start w-[calc(100%-41.5rem)] h-full py-4">
                              <div className="text-400 text-gray-300 font-normal">
                                Clock-In
                              </div>
                            </div>
                            <div className="flex justify-end text-center gap-[0.75rem] py-4">
                              {t.clockLogs.map((t1: any, index: number) => (
                                <div
                                  key={t1.date}
                                  className="flex justify-center items-center w-[5rem] h-full"
                                >
                                  <div className="text-200 font-normal text-gray-300">
                                    {" "}
                                    {t1.clockIn > "00:00:00"
                                      ? convertToAMPM(t1.clockIn)
                                      : "-"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="flex justify-start gap-2 h-[3.75rem] border-b border-gray-400">
                            <div className="flex items-center justify-start w-[calc(100%-41.5rem)] h-[3.75rem] py-4">
                              <div className="text-400 text-gray-300 font-normal flex justify-start items-center gap-[0.875rem] pe-2 w-full">
                                <span className="line-clamp-1">Clock-Out</span>
                              </div>
                            </div>
                            <div className="flex justify-end text-center gap-[0.75rem] py-4">
                              {t.clockLogs.map((t1: any, index: number) => (
                                <div
                                  key={t1.date}
                                  className="flex justify-center items-center w-[5rem] h-full"
                                >
                                  <div className="text-200 font-normal text-gray-300">
                                    {" "}
                                    {t1.clockOut > "00:00:00"
                                      ? convertToAMPM(t1.clockOut)
                                      : "-"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="flex justify-start gap-2 h-full">
                            <div className="flex items-center justify-start w-[calc(100%-41.5rem)] h-[3.75rem] py-4">
                              <div className="text-400 text-gray-300 font-normal">
                                Break
                              </div>
                            </div>
                            <div className="flex justify-end text-center gap-[0.75rem] py-4">
                              {t.clockLogs.map((t1: any, index: number) => (
                                <div
                                  key={t1.date}
                                  className="flex justify-center items-center w-[5rem] h-full"
                                >
                                  <div className="text-200 font-normal text-gray-300">
                                    {" "}
                                    {t1.breaks > "00:00:00"
                                      ? parseInt(t1.breaks.split(":")[0]) !== 0
                                        ? `${parseInt(
                                          t1.breaks.split(":")[0]
                                        )}h ${parseInt(
                                          t1.breaks.split(":")[1]
                                        )}m`
                                        : `${parseInt(
                                          t1.breaks.split(":")[1]
                                        )}m`
                                      : "-"}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <ClockInOutEmpty />
                )
              )
            ) : (
              <ClockInOutEmpty />
            )}
          </>
        )}
      </div>
      <div className="bg-white py-3 px-6 rounded-b-md">
        {" "}
        <Pagination
          currentPage={currentPage}
          itemsPerPage={pageSize}
          totalItems={count}
          onPageChange={handlePageChange}
          setFilterss={setFilterss}
          filterss={filterss}
          pageTitle={"Employees"}
        />
      </div>
    </div>
  );
};

export default ClockInOut;
