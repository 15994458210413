import React, { useEffect, useState } from "react";
import SingleMemberSearchDropdown from "./SingleMemberSearchDropdown";
import SingleScreenshotViewModal from "./SingleScreenshotViewModal";
import { getMyScreenShotsAsync } from "../../../services/reducers/teamsReducer";
import { addTimeAndFormat, formatAMPM } from "../../../utils/common";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getUsersAsync } from "../../../services/reducers/appReducer";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import ScreenshotsEmpty from "../../Screenshots/ScreenshotsEmpty";
import moment from "moment";

const TeamScreenshotsView = () => {
  const [singleScreenshot, setSingleScreenshot] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [screenshotList, setScreenshotList] = useState<any>([]);
  const [screenshotCount, setScreenshotCount] = useState();
  const [capturedList, setCapturedList] = useState<any>([]);
  const { identityId, date } = useParams();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const [userList, setUserList] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [selectedMember, setSelectedMember] = useState<any>();
  const [screenshotId, setScreenshotId] = useState("");
  const getMyScreensho = useAppSelector(
    (state: any) => state.teamDashboard.myScreenshot
  );

  useEffect(() => {
    getMyScreenshots();
  }, [identityId, date, selectedMember]);

  const getMyScreenshots = () => {
    const localDate = moment(date); 
    const utcDate = moment.utc(localDate);
    const formattedStartDate = utcDate.format('YYYY-MM-DD');
    if (identityId) {
      const request: any = {
        startDate: formattedStartDate,
        Page: 1,
        PageSize: 100,
        memberId: selectedMember ? selectedMember.identityId : identityId,
      };
      dispatch(getMyScreenShotsAsync(request)).then((res) => {
        if (res.payload) {
          setScreenshotList(res.payload.records);
          setScreenshotCount(res.payload.totalRecords);
          setCapturedList(
            res.payload.records.flatMap((t: any) => t.capturedScreenShots)
          );
        }
      });
    }
  };

  const userSearch = () => {
    const request = {
      page: currentPage,
      pageSize: pageSize,
      searchString: searchString,
    };
    dispatch(getUsersAsync(request)).then((res) => {
      const activeUsers = res?.payload?.users?.filter((user:any) => user.status === "Active");
      setUserList(activeUsers);
      // setUserList(res?.payload?.users);
    });
  };

  const debouncedUserSearch = useDebounce(userSearch, 1000);

  useEffect(() => {
    debouncedUserSearch();
  }, [searchString]);

  return (
    <div className="border border-gray-100 shadow-300 bg-white rounded-md relative h-[calc(100vh-8.1rem)] overflow-auto">
      <div
        className="py-[0.875rem]"
        style={{ background: "rgba(239, 239, 239, 0.40)" }}
      >
        <div className="w-[62.75rem] flex justify-between items-center">
          <div
            className="w-[calc(100%-51.875rem)] ps-5"
            onClick={() => navigate(-1)}
          >
            <button className="flex justify-start items-center text-200 text-gray-300 font- h-full">
              <span className="">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.57 5.92999L3.5 12L9.57 18.07"
                    stroke="#232323"
                    stroke-width="1"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
              BACK
            </button>
          </div>
          <div className="w-[51.875rem] flex justify-end items-center">
            <SingleMemberSearchDropdown
              userList={userList}
              setSelectedMember={setSelectedMember}
              selectedMember={selectedMember}
              searchString={searchString}
              setSearchString={setSearchString}
              identityId={identityId}
            />
          </div>
        </div>
      </div>
      <div className="py-2.5 px-10 h-[calc(100%-4rem)] overflow-auto">
        {screenshotList && screenshotList?.length > 0 ? (
          screenshotList.map((t: any, index: number) => (
            <div className="flex justify-start items-center border-b border-gray-400">
              <div className="w-[8.625rem] text-400 font-bold text-gray-300 text-start h-auto flex justify-start items-center">
                {formatAMPM(t?.capturedIn)}
              </div>
              <div className="inline-block min-w-[calc(100%-8.625rem)] overflow-x-auto whitespace-nowrap py-2.5">
                {t.capturedScreenShots.map((t1: any, index: number) => (
                  <div className="w-[9.375rem] inline-block mr-5">
                    <img
                      src={t1?.nevigationUrl}
                      alt="ScreenshotImage"
                      className="w-full h-[5.813rem] object-cover"
                    />
                    <div className="flex justify-between items-center mt-0.5">
                      <div className="text-200 text-gray-300 font-normal">
                        {addTimeAndFormat(t1?.capturedAt, 5, 30)}
                      </div>
                      <button
                        className="p-1"
                        onClick={() => {
                          setScreenshotId(t1.id);
                          setSingleScreenshot(true);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M10.5 4.5V1.5H7.5"
                            stroke="#3C3C3C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.5 7.5V10.5H4.5"
                            stroke="#3C3C3C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10.5 1.5L6.75 5.25"
                            stroke="#3C3C3C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.25 6.75L1.5 10.5"
                            stroke="#3C3C3C"
                            stroke-width="0.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <ScreenshotsEmpty type={"Admin"} />
        )}
      </div>
      {singleScreenshot && (
        <SingleScreenshotViewModal
          onClose={() => setSingleScreenshot(false)}
          capturedList={capturedList}
          getMyScreenshot={getMyScreenshots}
          setSingleScreenshot={setSingleScreenshot}
          screenshotId={screenshotId}
          type="Admin"
          getMyScreenshots={getMyScreensho}
        />
      )}
    </div>
  );
};

export default TeamScreenshotsView;
