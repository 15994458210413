import { ToastOptions } from "react-toastify"
export const Constants = {
    clientId: "0b530e9a-6a05-4f94-ac2c-1fb3ac6f5e96",
    clientScope: "openid profile email b8259469-f9af-4384-aed0-d93e4ddc895b_api",
    stsAuthority: "https://echo-unity.cerebrent.io",
    //clientRoot: "http://echopulse.millipixels.in/",
    clientRoot: "https://echo-pulse.cerebrent.io",
    apiRoot: "https://echo-pulse-api.cerebrent.io/",
    authApiRoot: "https://echo-unity.cerebrent.io",
    blobServiceApiRoot: "https://echo-blob-api.cerebrent.io",
}

export const API_URL = {
    INTEGRATION_API_URL: "https://echo-pulse-api.cerebrent.io/",
    AUTH_API_URL: "https://echo-auth-api.cerebrent.io/",
    EMPLOYEE_API_URL: "https://echo-employee-api.cerebrent.io/",
    CORE_API_URL: "https://echo-corehr-api.cerebrent.io/"
}
export const TOASTIFY_CONF: ToastOptions<{}> = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    rtl: false,
}
