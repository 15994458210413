import React from "react";
import ColorLabel from "../TimeOff/ColorLable";
import { convertMinutesToHoursAndMinutes } from "../../utils/common";

type props = {
  data: any;
};
const CustomLeaveTooltip: React.FC<props> = ({ data }) => {
  // console.log(data, "data");
  const isWeekend = (dateString: any) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };
  return (
    <div
      key={data.timeOffDate}
      // className="bg-white text-gray-300 px-3 pt-5 pb-2.5 mx-auto min-w-[166px] rounded-md absolute right-full border border-gray-400 z-[9999] text-left"
      // style={{
      //   boxShadow: "0px 8px 24px 0px rgba(0, 0, 0, 0.12)",
      // }}
    >
      <div className="h-4 w-4 bottom-auto top-2.5 left-full right-0 bg-white -rotate-45 absolute mx-auto -ml-2 border-r border-b border-gray-400"></div>
      {data.timeOffType === "Holiday" && (
        <div className="mb-3">
          <ColorLabel
            className="!w-full !h-auto px-2 py-2 flex justify-between items-center !rounded-md !text-white !text-100 font-normal gap-5"
            text={data.timeOffType}
          >
            <span className="whitespace-nowrap">{data.title ?? data.timeOffModeType}</span>
            {/* <span className="font-bold">
              {!isWeekend && convertMinutesToHoursAndMinutes(data.approvedTimeOffInMin)}
            </span> */}
          </ColorLabel>
          {/* <span className="text-gray-300 text-100 uppercase font-normal block mt-1">
        
        </span> */}
        </div>
      )}
      {data.timeOffType === "Leave" && (
        <div className="mb-3">
          <ColorLabel
            className="!w-full !h-auto px-2 py-2 flex justify-between items-center !rounded-md !text-white !text-100 font-normal gap-5"
            text={data.timeOffModeType}
          >
            <span className="whitespace-nowrap">{data.timeOffModeType}</span>
            {/* <span className="font-bold">
              {!isWeekend && convertMinutesToHoursAndMinutes(data.approvedTimeOffInMin)}
            </span> */}
          </ColorLabel>
        </div>
      )}
    </div>
  );
};

export default CustomLeaveTooltip;
