import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Checkbox from "../CheckBox/CheckBox";
import UserImage from "../UserImage/UserImage";
import Image from "../../images/Profile.png";
import Icons from "../Icons/Icon";

const SearchTypeContainer = styled.div`
  .searchmember-input {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.1243 6.02598C17.8453 5.76906 17.3931 5.76906 17.1141 6.02598L10.0002 12.5783L2.88619 6.02598C2.60725 5.76906 2.15499 5.76906 1.87604 6.02598C1.59709 6.28291 1.59709 6.69947 1.87604 6.95639L9.49509 13.9739C9.62904 14.0973 9.81072 14.1666 10.0002 14.1666C10.1896 14.1666 10.3713 14.0973 10.5052 13.9739L18.1243 6.95639C18.4032 6.69947 18.4032 6.28291 18.1243 6.02598Z' fill='%23232323'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
    background-size: 1rem;
  }
  .active .projectName {
    background-color: #f8f8f8;
  }
`;

interface SelectedMember {
  category: string;
  value: string;
}

type Member = {
  id: string;
  name: string;
};

type Props = {
  list: any;
  setSelectedMember: React.Dispatch<React.SetStateAction<any>>;
  selectedMember: any;
  option: string;
  setFieldValue: any;
  type: string;
  initialSelectedMembers?: any;
  onChange?: any;
  timeOffData?: any;
};

const TimeOffSearchType: React.FC<Props> = ({
  list,
  setSelectedMember,
  selectedMember,
  option,
  setFieldValue,
  type,
  initialSelectedMembers,
  onChange,
  timeOffData,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showAll, setShowAll] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    if (searchInput.trim() !== "") {
      setIsDropdownVisible(true);
    } else {
      setIsDropdownVisible(!isDropdownVisible);
    }
  };

  const getKeyForOption = (option: string) => {
    switch (option) {
      case "Designations":
        return "timeOffDesignations";
      case "Locations":
        return "timeOffLocations";
      case "Departments":
        return "timeOffDepartments";
      case "Employment Type":
        return "timeOffEmploymentTypes";
      case "Gender":
        return "timeOffGenders";
      case "Roles":
        return "timeOffRoles";
      case "":
        return "timeOffEmployees";
      default:
        return null;
    }
  };

  const getKeyForField = (option: string) => {
    switch (option) {
      case "Designations":
        return "designationId";
      case "Locations":
        return "locationId";
      case "Departments":
        return "departmentId";
      case "Employment Type":
        return "employmentTypeId";
      case "Gender":
        return "genderId";
      case "Roles":
        return "roleId";
      case "":
        return "identityId";
      default:
        return "identityId";
    }
  };

  const handleSelectMember = (e: any, option: string, id: string) => {
    const updatedSelectedMembers = { ...selectedMember };
    const targetId = e.target.checked;
    const updatedList = list.filter((obj: any) =>
      option === "" ? obj.identityId === id : obj.id === id
    );

    const applicableTo = type === "applicable";
    const notApplicableTo = type === "notApplicable";

    const selectedData = updatedList.map((item: any) => ({
      id: option === "" ? item.identityId : item.id,
      name: item.name,
      applicableTo,
      notApplicableTo,
    }));

    if (e.target.checked) {
      updatedSelectedMembers[option] = [
        ...(updatedSelectedMembers[option] || []),
        ...selectedData,
      ];
    } else {
      updatedSelectedMembers[option] = updatedSelectedMembers[option].filter(
        (obj: any) => obj.id !== id
      );
    }

    const fieldKey = getKeyForField(option);

    if (onChange) {
      onChange(updatedSelectedMembers[option]);
    }
    setSelectedMember(updatedSelectedMembers);

    setFieldValue(
      getKeyForOption(option),
      updatedSelectedMembers[option].map((member: any) => ({
        [fieldKey]: option === "" || option === "Employment Type" ? member.id : member.name,
        applicableTo: member.applicableTo,
        notApplicableTo: member.notApplicableTo,
      }))
    );
  };

  const removeSelectedMember = (memberId: string) => {
    const updatedSelectedMembers = { ...selectedMember };

    updatedSelectedMembers[option] = updatedSelectedMembers[option].filter(
      (m: any) => m.id !== memberId
    );

    const fieldKey = getKeyForField(option);
    if (onChange) {
      onChange(updatedSelectedMembers[option]);
    }
    setSelectedMember(updatedSelectedMembers);

    setFieldValue(
      getKeyForOption(option),
      updatedSelectedMembers[option].map((member: any) => ({
        [fieldKey]: option === "" || option === "Employment Type" ? member.id : member.name,
        applicableTo: member.applicableTo,
        notApplicableTo: member.notApplicableTo,
      }))
    );
  };

  useEffect(() => {
    if (initialSelectedMembers) {
      const updatedSelectedMembers = { ...selectedMember };

      const extractedIds: SelectedMember[] = initialSelectedMembers?.map((item: any) => {
        const [category, value] = item.split(".");
        return { category, value };
      });

      const initialMembers = list?.filter((item: any) => {
        return extractedIds.some(({ category, value }) => {
          return category === "Gender" && item.name === value ||
            category === "Employment Type" && item.id === (isNaN(parseInt(value)) ? value : parseInt(value)) ||
            //  category === '""' && item.id === (isNaN(parseInt(value)) ? value : parseInt(value)) ||
            category === "Locations" && item.name === value ||
            category === "Roles" && item.name === value ||
            category === "Designations" && item.name === value ||
            category === "Departments" && item.name === value ||
            category === '""' && item.identityId === value;
        });
      });

      const selectedData = initialMembers?.map((item: any) => ({
        id: option === "" ? item.identityId : item.id,
        name: item.name,
        applicableTo: type === "applicable",
        notApplicableTo: type === "notApplicable",
      }));
    
      if (JSON.stringify(updatedSelectedMembers[option]) !== JSON.stringify(selectedData)) {
        updatedSelectedMembers[option] = selectedData || [];
        setSelectedMember(updatedSelectedMembers);
      }
      if (onChange) {
        onChange(updatedSelectedMembers);
      }
    }
  }, [option, type, list]);

  const filteredList = searchInput.trim()
    ? list.filter((item: any) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    )
    : list;
  console.log(filteredList, "filteredList")
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleShowAllMembers = () => {
    setShowAll(!showAll);
  };
  console.log(selectedMember, "initialSelectedMembers")
  return (
    <SearchTypeContainer>
      <div className="relative" ref={dropdownRef}>
        <input
          type="text"
          placeholder="Select"
          className="outline-none w-full bg-white border border-gray-100 ps-4 pe-4 py-[0.813rem] text-400 font-normal rounded-md text-gray-300 placeholder:text-gray-700 
          focus:border-gray-900 focus:shadow-200
          disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100 disabled:text-gray-700 searchmember-input"
          value={searchInput}
          onFocus={toggleDropdown}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        {/* <div className="absolute left-0 top-[46px] bg-white border-gray-400 shadow-100 rounded-md z-20 w-full border flex justify-start items-center px-4 py-2.5 text-400 text-gray-300 gap-2.5 min-h-[3.5rem]">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="#D9D9D9"
              stroke-width="4"
            ></circle>
            <path
              className="opacity-75"
              fill="#4A154B"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>{" "}
          <span className="">Loading...</span>
        </div> */}
        {/* <div className="bg-white border border-gray-100 rounded-md rounded-t-0 shadow-200 w-full absolute left-0 top-[3.063rem] z-50">
          <ul className="overflow-auto min-h-11 max-h-[14rem] py-2">
            <li className="py-1.5 px-5 relative font-medium w-full flex justify-center items-center text-400">
              No result found
            </li>
            <li className="py-1.5 px-5 relative font-medium w-full flex justify-start items-center gap-2">
              <Checkbox checked={true} onChange={function noRefCheck() {}} />
              <p className="text-400 font-medium text-gray-300 -mt-1 mb-0">Male</p>
            </li>
            <li className="py-1.5 px-5 relative font-medium w-full flex justify-start items-center gap-2">
              <Checkbox checked={true} onChange={function noRefCheck() {}} />
              <p className="text-400 font-medium text-gray-300 -mt-1 mb-0">Female</p>
            </li>
            <li className="py-1.5 px-5 relative font-medium w-full flex justify-start items-center gap-2">
              <Checkbox checked={true} onChange={function noRefCheck() {}} />
              <p className="text-400 font-medium text-gray-300 -mt-1 mb-0">Other</p>
            </li>
          </ul>
        </div> */}
        {isDropdownVisible && (
          <div className="bg-white border border-gray-100 rounded-md rounded-t-0 shadow-200 w-full absolute left-0 top-[3.063rem] z-50">
            <ul className="overflow-auto min-h-11 max-h-[14rem] py-2">
              {filteredList && filteredList.length > 0 ? (
                filteredList.map((item: any) => (
                  <li
                    key={item.id}
                    className="py-1.5 px-5 relative font-medium w-full flex justify-start items-center gap-2 cursor-pointer hover:bg-gray-100"
                  >
                    <Checkbox
                      checked={selectedMember[option]?.some((m: any) =>
                        option === "" ? m.id === item.identityId : m.id === item.id
                      )}
                      onChange={(e: any) => handleSelectMember(e, option, option === "" ? item.identityId : item.id)}
                      disabled={timeOffData?.isConsumed}
                    />
                    <p className="text-400 font-medium text-gray-300 mb-0">
                      {item.name}
                    </p>
                  </li>
                ))
              ) : (
                <li className="py-1.5 px-5 relative font-medium w-full flex justify-center items-center text-400">
                  No result found
                </li>
              )}
            </ul>
          </div>
        )}
      </div>

      <ul className="inline-block">
        {selectedMember[option]?.map((member: any, index: number) => (
          <li key={member.id} className={`inline-block ${index >= 4 && !showAll ? "hidden" : ""}`}>
            <div className="flex items-center px-3 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5">
              <span className="me-1.5">{member.name}</span>
              <div className={`${timeOffData?.isConsumed ? 'pointer-events-none opacity-50' : 'cursor-pointer'}`} onClick={() => removeSelectedMember(member.id)}>
                <Icons name="cross" variant="stroke" size={10} className="-mb-0.5" />
              </div>
            </div>
          </li>
        ))}
        {selectedMember[option]?.length > 4 && (
          <li
            className="inline-block text-center cursor-pointer px-3 py-1.5 bg-gray-400 rounded-md mt-2.5 text-400 font-medium text-gray-300 me-2.5"
            onClick={toggleShowAllMembers}
          >
            {showAll ? "Collapse view" : `+${selectedMember[option]?.length - 4} others`}
          </li>
        )}
      </ul>
    </SearchTypeContainer>
  );
};


export default TimeOffSearchType;
