// Timer.js
import React, { useState, useEffect, useRef } from "react";
import { getListAsync } from "../../services/reducers/timeReducer";
import { useAppDispatch } from "../../hooks";

export interface TimerProps {
  initialMinutes: number;
  identifier: string;
  date: string;
  onClick?: () => void;
  deactivateTimmer?: () => void;
  setShowNotification: React.Dispatch<React.SetStateAction<boolean>>;
  // intList:any;
  setShowNotification1: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNotificationLock: React.Dispatch<React.SetStateAction<boolean>>;
}
const Timer: React.FC<TimerProps> = ({
  initialMinutes,
  identifier,
  date,
  onClick,
  deactivateTimmer,
  setShowNotification,
  setShowNotification1,
  setShowNotificationLock,
}) => {
  const dispatch = useAppDispatch();
  const [seconds, setSeconds] = useState(initialMinutes * 60);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateTimer = () => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    };

    // Set up the initial interval
    intervalRef.current = setInterval(updateTimer, 1000);

    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Cleanup function to clear the interval and remove the event listener when the component unmounts
    // Cleanup function to clear the interval when the component unmounts
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      }
    };
  }, [identifier, date, deactivateTimmer, onClick, dispatch]);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      const param = {
        StartDate: date,
        EndDate: date,
        PageSize: 100,
        ViewType: 0,
        Page: 1,
        // "OrganizationId":intList?.records?.length>0 ? intList?.records[0]?.id :"",
      };

      dispatch(getListAsync(param)).then((res) => {
        const activeTimerItem = res.payload.item1.records.filter(
          (item: any) => item.identifier === identifier
        );
        const activeTask = activeTimerItem[0].tasksList.filter(
          (item: any) => item.isTimerActive
        );
        if (activeTask.length === 0 && deactivateTimmer && onClick) {
          deactivateTimmer();
        }
      });
    }
  };

  // Helper function to format seconds into HH:MM:SS
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  return (
    <div className="text-center bg-primary-100 ps-1 px-1.5 py-1 w-[calc(5rem-0.50rem)] rounded-full">
      <div
        className="flex justify-center items-center gap-0.5 w-full"
        onClick={() => {
          setShowNotification(false);
          setShowNotification1(false);
          setShowNotificationLock(false);
          onClick && onClick();
        }}
      >
        <span className="">
          {/* <svg width="6" height="8" xmlns="http://www.w3.org/2000/svg">
            <path fill="#FFF" d="M0 0h2v10H0zm4 0h2v10H4z" />
          </svg> */}
          <svg
            width="14"
            height="14"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.97 22C17.4928 22 21.97 17.5228 21.97 12C21.97 6.47715 17.4928 2 11.97 2C6.44712 2 1.96997 6.47715 1.96997 12C1.96997 17.5228 6.44712 22 11.97 22Z"
              stroke="#FFFFFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.73 16.23H13.27C15.39 16.23 16.23 15.38 16.23 13.27V10.73C16.23 8.61002 15.38 7.77002 13.27 7.77002H10.73C8.61002 7.77002 7.77002 8.62002 7.77002 10.73V13.27C7.77002 15.38 8.62002 16.23 10.73 16.23Z"
              fill="#FFFFFF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>{" "}
        <div className="w-[calc(100%-19px)] text-100 font-medium text-white">
          {formatTime(seconds)}
        </div>
      </div>
    </div>
  );
};

export default Timer;
